import { ThemeProvider } from "@emotion/react";
import {
  Box,
  Typography,
  Paper,
  Container,
  Unstable_Grid2 as Grid,
  Divider,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import Theme from "./Theme";
import ReactPlayer from "react-player";

const testimonialItems = [
  {
    video: "https://www.youtube.com/watch?v=ck4RTyzssE4",
    title: "Zaq Baker - Someone to Believe In",
    description: `Out of This World did incredible work on the music video for my original song, "Someone to Believe In" — They proposed the video; devised the concept and storyboard; scheduled filming, and organized extra. OOTW maintained an open mind and great sense of humor throughout the process.`,
    clientName: "Zaq Baker",
    clientPosition: "Musician",
  },
  {
    video: "https://vimeo.com/795920161",
    title: "Out of This World Productions Demo Real",
    description:
      "Our dynamic duo of young filmmakers bring a fresh perspective and unparalleled passion to every project, elevating brands to new heights. With our expertise in captivating storytelling and eye-catching design, we simplify the production process and make it a truly enjoyable experience.",
    clientName: "Josiah Lydon",
    clientPosition: "Co-Owner, Out of This World Productions",
  },
];

export default function OurWork({ bgColor }) {
  const isMobile = useMediaQuery(Theme.breakpoints.down("md"));
  return (
    <ThemeProvider theme={Theme}>
      <Box py={8} id="ourWork">
        <Container sx={{ my: 0, py: 0 }} maxWidth="xl">
          <Grid
            alignItems="center"
            justifyContent="center"
            container
            width="100%"
            sx={{ m: 0 }}
            columnSpacing={{ xs: 1, sm: 3, md: 5 }}
            rowSpacing={{ xs: 6, sm: 8, md: 8 }}
          >
            <Typography px={1} variant="h3">
              We Make Exceptional Videos That Work: <br /> Take it From our
              Clients
            </Typography>
            {testimonialItems.map((item, index) => {
              const isReversed = index % 2 === 0;
              const isLast = testimonialItems.length === index + 1;
              return (
                <div>
                  <Grid
                    flexDirection={isReversed ? "row-reverse" : "row"}
                    display="flex"
                    flexWrap={"wrap"}
                    key={item}
                    width="100%"
                  >
                    <Grid
                      xs={12}
                      sm={6}
                      display="flex"
                      justifyContent="top"
                      alignItems="top"
                      flexDirection="column"
                    >
                      <Box
                        overflow="hidden"
                        component={Paper}
                        elevation={10}
                        width="100%"
                        position="relative"
                        paddingTop="56.25%"
                        backgroundColor={"black"}
                      >
                        <ReactPlayer
                          style={{ position: "absolute", top: "0", left: "0" }}
                          controls={true}
                          muted={true}
                          autoplay={true}
                          light={
                            isMobile ? (
                              <img
                                style={{ height: "auto", width: "50%" }}
                                src="Images/OOTW_Earth_Md.png"
                                alt="Logo of Out of This World Productions"
                              />
                            ) : (
                              false
                            )
                          }
                          loop={true}
                          config={{
                            vimeo: {
                              playerOptions: {
                                autoplay: true,
                                responsive: true,
                                autopause: false,
                              },
                            },
                            youtube: {
                              playerVars: {
                                autoplay: true,
                                responsive: true,
                              },
                            },
                          }}
                          url={item.video}
                          width="100%"
                          height="100%"
                        ></ReactPlayer>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6}>
                      <Typography
                        sx={{ verticalAlign: "top" }}
                        variant={"h4"}
                        component="h2"
                        mb={2}
                      >
                        {item.title}
                      </Typography>
                      <Typography fontWeight={300} mb={2}>
                        "{item.description}"
                      </Typography>
                      <Typography>
                        {item.clientName}
                        <br />
                        {item.clientPosition}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box width="100%" textAlign="center">
                    <Divider sx={{ display: isLast ? "none" : "flex" }} />
                  </Box>
                </div>
              );
            })}
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
