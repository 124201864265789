import * as React from "react";
import Theme from "./Theme";
import { Button, Modal, ThemeProvider, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import ContactModal from "./ContactModal";
import PubSub from "pubsub-js";

export default function ContactButton({ size, text, variant, color }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <ThemeProvider theme={Theme}>
      <Button
        onClick={handleOpen}
        sx={{ textTransform: "none" }}
        variant={variant}
        size={size}
        color={color}
      >
        {text}
      </Button>
      <Modal
        sx={{ overflowY: "scroll", width: "100vw", zIndex: "9999" }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ContactModal modalControl={handleClose} />
      </Modal>
    </ThemeProvider>
  );
}
