import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import Box from "@mui/material/Box";
import {
  Paper,
  TextField,
  Button,
  IconButton,
  ThemeProvider,
  Typography,
  Container,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CloseIcon from "@mui/icons-material/Close";
import Theme from "./Theme";
import PubSub from "pubsub-js";

export default function ContactModal({ modalControl }) {
  const form = useRef();

  function handleFormSubmission(isSent, submissionMsg) {
    const submissionData = { success: isSent, msg: submissionMsg };
    PubSub.publish("FORM_SUBMISSION", submissionData);
  }

  function OnChangeHandler(e) {
    console.log(e);
  }

  const sendEmail = (e) => {
    e.preventDefault();
    if (!form.current.checkValidity()) {
      form.current.reportValidity();
      handleFormSubmission(
        false,
        "Please ensure the form is correctly completed."
      );
      return;
    }
    console.log(form);

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUB_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          modalControl();
          handleFormSubmission(true, result.text);
        },
        (error) => {
          console.log(error.text);
          console.log(process.env.REACT_APP_PUB_KEY);
          handleFormSubmission(false, error.text);
        }
      );
  };

  function onModalBackdropClick(e) {
    //Comment in for modal to dissapear on backdrop click
    // console.log(e.target.className);
    // if (
    //   e.target.className === "MuiBox-root css-1yvnh5b" ||
    //   e.target.className ===
    //     "MuiGrid2-root MuiGrid2-container MuiGrid2-direction-xs-row css-1lpcynv-MuiGrid2-root"
    // ) {
    //   modalControl();
    // } else return;
    // e.stopPropagation();
    return;
  }

  return (
    <ThemeProvider theme={Theme}>
      <Box
        component="form"
        ref={form}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height: "90%",
          width: "100%",
        }}
        noValidate
        autoComplete="off"
        onSubmit={sendEmail}
        onClick={(e) => onModalBackdropClick(e)}
        onChange={(e) => OnChangeHandler(e)}
      >
        <Container width="md">
          <Grid container padding={{ md: 2 }} justifyContent={"center"}>
            <Grid
              container
              display="flex"
              flexDirection="column"
              justifyContent="center"
              xs={12}
              sm={8}
              md={6}
              component={Paper}
            >
              <IconButton
                size="small"
                aria-label="site menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={modalControl}
                color="inherit"
                sx={{ marginLeft: "auto" }}
              >
                <CloseIcon
                  fontSize="small"
                  sx={{ color: "rgba(0, 0, 0, 0.75)" }}
                />
              </IconButton>
              <Grid textAlign="center" px={2} py={1}>
                <Typography gutterBottom variant="h4" fontWeight={500}>
                  Let's talk video.
                </Typography>
                <Typography variant="body">
                  Please fill out the form below, and we will follow up within 48 hours.
                </Typography>
              </Grid>
              <Grid
                gap={2}
                padding={2}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Grid
                  flexDirection="row"
                  gap={2}
                  display="flex"
                  flexWrap={{ xs: "wrap", sm: "nowrap" }}
                >
                  <Grid xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      label="First Name"
                      variant="outlined"
                      name="user_first_name"
                      type="text"
                    />
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      label="Last Name"
                      variant="outlined"
                      name="user_last_name"
                      type="text"
                    />
                  </Grid>
                </Grid>
                <Grid
                  flexDirection="row"
                  gap={2}
                  display="flex"
                  flexWrap={{ xs: "wrap", sm: "nowrap" }}
                >
                  <Grid xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Company Name"
                      variant="outlined"
                      name="user_company_name"
                    />
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      variant="outlined"
                      name="user_phone_number"
                      type="tel"
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    />
                  </Grid>
                </Grid>
                <TextField
                  required
                  type="email"
                  label="Email"
                  variant="outlined"
                  name="user_email"
                />
                <TextField
                  required
                  multiline
                  rows={4}
                  label="Message"
                  variant="outlined"
                  name="message"
                  type="text"
                />
                <Button
                  sx={{ my: 1, width: "50%", mx: "auto" }}
                  color="secondary"
                  variant="contained"
                  type="submit"
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
