import { createTheme, responsiveFontSizes } from "@mui/material";
import RobotoFlex from "./fonts.css";

const themeBase = createTheme({
  palette: {
    primary: {
      // main: "#00a1e4",
      main: "#161717",
    },
    secondary: {
      main: "#f1592a",
    },
    accentLight: {
      main: "#F2F2F2",
    },
    primaryTransparent: {
      main: "RGBA(0, 161, 228, 0.85)",
    },
    accentDark: {
      main: "#161717",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          textTransform: "none",
        },
      },
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif'
  },
});

const theme = responsiveFontSizes(themeBase);

export default theme;
