import React from "react";
import {
  Unstable_Grid2 as Grid,
  Paper,
  Typography,
  Box,
  ThemeProvider,
  Container,
  IconButton,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";

import Theme from "./Theme";

const Team = () => {
  const teamMembers = [
    {
      name: "Josiah Lydon",
      title: "Co-Owner, Director",
      description:
        "Josiah is an experienced developer with a passion for building innovative web applications.",
      image: "/Images/Josiah_1.png",
      twitterURL: "https://www.twitter.com/OOTWProduction",
      instagramURL: "https://www.instagram.com/josiahlydum/",
      emailURL: "ootw.techmedia@gmail.com",
    }
  ];

  return (
    <ThemeProvider theme={Theme}>
      <Box id="team" backgroundColor={Theme.palette.accentLight.main}>
        <Container sx={{ py: 8 }} width="md">
          <Typography textAlign="center" variant="h3" mb={5}>
            Meet our Director
          </Typography>
          <Grid
            justifyContent="center"
            sx={{ mx: 0 }}
            rowSpacing={{ xs: 6, sm: 0 }}
            columnSpacing={{ xs: 0, sm: 6 }}
            container
          >
            {teamMembers.map((member, index) => (
              <Grid xs={12} sm={6} md={5} key={index}>
                <Paper
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    overflow: "hidden",
                  }}
                >
                  <img
                    style={{
                      height: "auto",
                      width: "auto",
                      maxWidth: "100%",
                      maxHeight: "100%",
                    }}
                    src={member.image}
                    alt={member.name}
                  />
                  <Box
                    flexDirection="column"
                    display="flex"
                    alignItems="space-between"
                    p={2}
                    height="100%"
                  >
                    <Box flexDirection="column" display="flex" flexGrow="1">
                      <Typography variant="h5">{member.name}</Typography>
                      <Typography color="text.secondary" variant="subtitle1">
                        {member.title}
                      </Typography>
                      <Typography color="text.secondary" variant="body1">
                        {member.description}
                      </Typography>
                    </Box>
                    <Box>
                      {member.twitterURL && (
                        <IconButton href={member.twitterURL}>
                          <TwitterIcon />
                        </IconButton>
                      )}
                      {member.instagramURL && (
                        <IconButton href={member.instagramURL}>
                          <InstagramIcon />
                        </IconButton>
                      )}
                      {member.facebookURL && (
                        <IconButton href={member.facebookURL}>
                          <FacebookIcon />
                        </IconButton>
                      )}
                      {member.emailURL && (
                        <IconButton href={member.emailURL}>
                          <EmailIcon />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Team;
