import { ThemeProvider } from "@emotion/react";
import {
  Box,
  Typography,
  Paper,
  Container,
  Button,
  Divider,
} from "@mui/material";
import React from "react";
import Theme from "./Theme";
import ReactPlayer from "react-player";
import "./ChromaticText.css";

export default function ShowReel() {
  return (
    <ThemeProvider theme={Theme}>
      <Box
        id="showReel"
        backgroundColor={Theme.palette.primary.main}
        sx={{
          backgroundSize: "contain",
          backgroundImage: `
            linear-gradient(
        rgba(22, 23, 23, 1),
        rgba(22, 23, 23, 0.90),
        rgba(22, 23, 23, 1)), URL("Images/starfield.jpg")`,
        }}
      >
        <Container sx={{ py: 8 }} maxWidth="md">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            textAlign="center"
            color="white"
          >
            <Typography
              className="chromaticText"
              maxWidth="610px"
              variant="h3"
              component="h2"
              fontSize="1.9rem"
            >
              Video Production That Is
            </Typography>
            <Typography
              sx={{
                textShadow: "1px 0 10px",
              }}
              maxWidth="610px"
              variant="h3"
              component="h2"
              fontSize="1.9rem"
              color="secondary"
              mb={4}
            >
              Out of This World
            </Typography>
            <Box
              overflow="hidden"
              component={Paper}
              elevation={10}
              width="100%"
              position="relative"
              paddingTop="56.25%"
              backgroundColor={"black"}
            >
              <ReactPlayer
                playing={false}
                style={{ position: "absolute", top: "0", left: "0" }}
                controls={true}
                light={<img style={{height: 'auto', width: '50%'}} src="Images/OOTW_Earth_Md.png" />}
                muted={true}
                autoplay={true}
                loop={true}
                config={{
                  vimeo: {
                    playerOptions: {
                      autoplay: true,
                      responsive: true,
                    },
                  },
                }}
                url={"https://vimeo.com/795920161"}
                width="100%"
                height="100%"
              ></ReactPlayer>
            </Box>
            <Typography
              fontSize={"1.3rem"}
              fontWeight={400}
              mt={4}
              maxWidth="610px"
            >
              Our dynamic duo of young filmmakers bring a fresh perspective and
              unparalleled passion to every project, elevating brands to new
              heights. With our expertise in captivating storytelling and
              eye-catching design, we simplify the production process and make
              it a truly enjoyable experience.
            </Typography>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
