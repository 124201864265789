import React from "react";
import Box from "@mui/material/Box";
import { Button, Container, ThemeProvider, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Theme from "./Theme";
import ContactButton from './ContactButton'
import './ChromaticText.css'

export default function Hero() {
  return (
    <ThemeProvider theme={Theme}>
      <Box
        display="flex"
        sx={{ transform: "translate(-50%, -25%)" }}
        gap={5}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems="center"
        position={"absolute"}
        top="50%"
        left="50%"
        zIndex={100}
        width="100%"
        id="hero"
      >
        <Container maxWidth={"md"}>
          <Typography
            // className="chromaticText"
            variant="h2"
            component="h2"
            textAlign="left"
            color="white"
            fontWeight={400}
            mb={4}
          >
            Prepare for Launch With Out of This World Productions
          </Typography>
          <ContactButton
            displayToast={false}
            color="secondary"
            text="Contact Us"
            size="large"
            variant="contained"
          />
        </Container>
      </Box>
      <Box
        sx={{
          backgroundSize: "contain",
          backgroundImage: `
          linear-gradient(
      rgba(22, 23, 23, 0.35), 
      rgba(22, 23, 23, 1)), URL("Images/starfield.jpg")`,
        }}
        backgroundColor={Theme.palette.primary.main}
        position="static"
        top={0}
        left={0}
        width="100%"
        height="100%"
        overflow="hidden"
        display="flex"
        justifyContent="center"
        zIndex={-1}
      >
        <iframe
          src="https://player.vimeo.com/video/795920161?h=82292caafb&autoplay=1&loop=1&title=0&byline=0&portrait=0&transparent=0&background=1#t=1s"
          style={{
            position: "static",
            left: "50%",
            width: "100vw",
            height: "56.25vw",
            minHeight: "100vh",
            minWidth: "177.77vh",
            border: "0px",
          }}
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Box>
    </ThemeProvider>
  );
}
