import {
  Container,
  List,
  ListItem,
  Typography,
  ListItemIcon,
  ThemeProvider,
  ListItemText,
  Box,
  Divider,
} from "@mui/material";
import Theme from "./Theme";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";

const services = [
  {
    service: "Pre-Production",
    description:
      "Collaborating with clients to develop ideas, write scripts, plan shoots, and prepare for production.",
  },
  {
    service: "Production",
    description:
      "Capturing high-quality video and audio on location or in-studio using professional equipment and techniques.",
  },
  {
    service: "Post-Production",
    description:
      "Editing raw footage, adding visual effects, sound design, music, and narration to create a polished final product.",
  }
];

function ServicesList() {
  return (
    <ThemeProvider theme={Theme}>
      <Container maxWidth="md" id="services" sx={{ py: 5 }}>
        <Typography variant="h3">Our Services</Typography>
        <Box width="100%" textAlign="center">
          <Divider sx={{ display: "inline-block", width: "100%" }} />
        </Box>
        <List>
          {services.map((service) => (
                <ListItem
                  key={service}
                >
                  <ListItemIcon>
                    <CircleOutlinedIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="h5">{service.service}</Typography>
                    }
                    secondary={
                      <Typography color="text.secondary" variant="body1">
                        {service.description}
                      </Typography>
                    }
                  />
                </ListItem>
          ))}
        </List>
      </Container>
    </ThemeProvider>
  );
}

export default ServicesList;
