import * as React from "react";
import Theme from "./Theme";
import {
  ThemeProvider,
  Box,
  Container,
  Typography,
  Button,
} from "@mui/material";
import ContactButton from "./ContactButton";
import { useRef } from "react";

export default function ActionBanner() {
   //Use space themed background for final call to action?

  return (
    <ThemeProvider theme={Theme}>
      <Box
        py={10}
        sx={{
          backgroundSize: "contain",
          backgroundImage: `
          linear-gradient(
      rgba(22, 23, 23, 0.35), 
      rgba(22, 23, 23, 1)), URL("Images/starfield.jpg")`,
        }}
        backgroundColor={Theme.palette.primary.main}
      >
        <Container maxWidth="md">
          <Box
            flexDirection="column"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography textAlign="center" color="white" mb={3} variant="h3">
              Drop us a DM and Launch Your Brand to New Heights
            </Typography>

            <ContactButton
              color="secondary"
              text="Contact Us"
              size="large"
              variant="contained"
              displayToast={true}
            />
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
