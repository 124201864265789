import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import CloseIcon from "@mui/icons-material/Close";
import ListItemText from "@mui/material/ListItemText";
// import OOTW_Logo from "/Images/OOTW_Earth_Logo.png";
import Theme from "./Theme";
import { ThemeProvider } from "@emotion/react";
import ContactButton from "./ContactButton";
import { Link, animateScroll as scroll } from "react-scroll";

const sections = [
  { section: "About Us", id: "showReel" },
  { section: "Our Work", id: "ourWork" },
  { section: "Team", id: "team" },
  { section: "Services", id: "services" },
];

const scrollToTop = () => {
  scroll.scrollToTop();
};

function Navbar() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = () => (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(!drawerOpen);
  };

  return (
    <ThemeProvider theme={Theme}>
      <AppBar
        color={"primary"}
        sx={{
          zIndex: "1400",
        }}
        position="sticky"
      >
        <Container maxWidth="md">
          <Toolbar
            sx={{ justifyContent: "space-between" }}
            disableGutters
            variant="dense"
          >
            <Box mr={2}>
              <Button
                sx={{
                  borderRadius: "50%",
                  padding: 0,
                  margin: 0,
                  height: "40px",
                }}
                onClick={scrollToTop}
              >
                <img
                  style={{ height: "inherit" }}
                  src={"/Images/OOTW_Earth_Logo.png"}
                  alt=""
                  srcset=""
                />
              </Button>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {sections.map((section) => (
                <Link
                  key={section.section}
                  to={section.id}
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <Button
                    sx={{
                      color: "RGBA(250, 250, 250, 0.85)",
                      display: "block",
                      fontWeight: 400,
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    {section.section}
                  </Button>
                </Link>
              ))}
            </Box>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <ContactButton
                text="Contact Us"
                size="small"
                variant="contained"
                color="secondary"
                sx={{
                  "&a": { color: "RGBA(250, 250, 250, 0.85)" },
                  "&a:hover": { color: "white" },
                }}
              />
            </Box>
            <Box
              sx={{
                flexGrow: 0,
                display: { xs: "flex", md: "none" },
                height: "40px",
              }}
            >
              <IconButton
                size="large"
                aria-label="site menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer(true)}
                color="inherit"
                sx={{}}
              >
                {drawerOpen ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
              <Box>
                <Drawer
                  sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.80)",
                    "& .MuiDrawer-paperAnchorTop, .MuiModal-backdrop": {
                      top: "48px",
                    },
                  }}
                  anchor="top"
                  open={drawerOpen}
                  onClose={() => setDrawerOpen(false)}
                >
                  <Container maxWidth="md">
                    <Box
                      sx={{}}
                      role="presentation"
                      onClick={toggleDrawer()}
                      onKeyDown={toggleDrawer()}
                    >
                      <List sx={{}}>
                        {sections.map((section, index) => (
                          <ListItem
                            key={section.section}
                            disablePadding
                            sx={{
                              width: "100%",
                              borderBottom: "1px solid rgba(0, 0, 0, 0.25)",
                              "& a": {
                                width: "100%",
                              },
                            }}
                          >
                            <Link
                              key={section.section}
                              to={section.id}
                              spy={true}
                              smooth={true}
                              duration={500}
                              onClick={toggleDrawer()}
                            >
                              <ListItemButton>
                                <ListItemText
                                  primary={section.section}
                                  color="white"
                                />
                              </ListItemButton>
                            </Link>
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                    <List
                      sx={{
                        width: "100%",
                        "& a": {
                          width: "100%",
                        },
                      }}
                    >
                      <ListItem sx={{ justifyContent: "center" }}>
                        <ContactButton
                          text="Contact Us"
                          size="large"
                          variant="contained"
                          color="secondary"
                          displayToast={false}
                        />
                      </ListItem>
                    </List>
                  </Container>
                </Drawer>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
}
export default Navbar;
