import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  ThemeProvider,
  Typography,
  ListItems,
  IconButton,
} from "@mui/material";
import React from "react";
import theme from "./Theme";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import YouTubeIcon from "@mui/icons-material/YouTube";
import * as Scroll from "react-scroll";
import { Link, animateScroll as scroll } from "react-scroll";
import ContactToast from "./ContactToast";

export default function Footer() {
  const sections = [
    { section: "About Us", id: "showReel" },
    { section: "Our Work", id: "ourWork" },
    { section: "Team", id: "team" },
    { section: "Services", id: "services" },
  ];

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        className="Footer"
        justifyContent="center"
        flexDirection="column"
        display="flex"
        sx={{
          background: theme.palette.primary.main,
          color: "white",
          minHeight: "200px",
        }}
      >
        <Box px={{ xs: "10px", md: "150px" }} pb={1}>
          <Box
            sx={{
              borderBottom: "1px solid RGBA(250, 250, 250, 0.75)",
              borderTop: "1px solid RGBA(250, 250, 250, 0.75)",
              height: { xs: "100px", md: "150px" },
              justifyContent: "space-around",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "no-wrap",
            }}
          >
            <Button
              sx={{
                borderRadius: "50%",
                padding: 0,
                margin: 0,
                height: "45px",
              }}
              onClick={scrollToTop}
            >
              <img
                style={{ height: "inherit" }}
                src={"/Images/OOTW_Earth_Logo.png"}
                alt=""
                srcset=""
              />
            </Button>
            <Box color="RGBA(250, 250, 250, 0.75)">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  gap: "5px",
                }}
              >
                <Box
                  sx={{
                    "& .MuiButtonBase-root": {
                      color: "inherit",
                    },
                  }}
                >
                  <IconButton
                    rel="noopener noreferrer"
                    href="https://twitter.com/OOTWProduction"
                    target="_blank"
                  >
                    <TwitterIcon />
                  </IconButton>
                  <IconButton
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/ootw_techmedia/"
                    target="_blank"
                  >
                    <InstagramIcon />
                  </IconButton>

                  <IconButton
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/@jojovlogs"
                    target="_blank"
                  >
                    <YouTubeIcon />
                  </IconButton>
                </Box>
              </Box>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  gap: "5px",
                }}
              >
                <EmailIcon color="secondary" />
                <a
                  style={{
                    textDecoration: "none",
                    color: "white",
                    opacity: "0.75",
                  }}
                  href="mailto:ootw.techmedia@gmail.com"
                  subject="Production Request:"
                >
                  ootw.techmedia@gmail.com
                </a>
              </Typography>
            </Box>
          </Box>
          <Box
            marginLeft={-1}
            flexWrap="wrap"
            flexDirection="row"
            display="flex"
            alignItems="baseline"
            justifyContent={{ xs: "center", md: "space-between" }}
          >
            <List
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "fill",
              }}
            >
              {sections.map((section, index) => (
                <ListItem
                  sx={{ paddingX: 1, paddingY: 0, width: "unset" }}
                  dense
                  key={index}
                >
                  <Link
                    key={section.section}
                    to={section.id}
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                  >
                    <Button
                      size="small"
                      sx={{
                        color: "white",
                        opacity: "0.75",
                        display: "block",
                        textTransform: "none",
                      }}
                    >
                      {section.section}
                    </Button>
                  </Link>
                </ListItem>
              ))}
            </List>
            <Typography
              fontSize=".8rem"
              fontWeight={200}
              sx={{ opacity: 0.75, textAlign: "center" }}
            >
              Copyright &copy; 2024 Out Of This World Productions LLC
            </Typography>
          </Box>
        </Box>
      </Box>
      <ContactToast />
    </ThemeProvider>
  );
}
