import "./App.css";
import Hero from "./Hero";
import Navbar from "./Navbar";
import ShowReel from "./ShowReel";
import Footer from "./Footer";
import OurWork from "./OurWork";
import Team from "./Team";
import ActionBanner from "./ActionBanner";
import ServicesList from "./Services";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <ShowReel bgColor="#161717" />
      <OurWork />
      <Team />
      <ServicesList/>
      <ActionBanner />
      <Footer />
    </div>
  );
}

export default App;
