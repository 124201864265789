import { ThemeProvider, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React from "react";
import Theme from "./Theme";
import PubSub from "pubsub-js";

export default function ContactToast() {
  const [toastOpen, setToastOpen] = React.useState({
    open: false,
    success: true,
    msg: "Message sent.",
  });


  const displayToastSuccess = (publisher, submissionData) => {
    const toastState = { ...toastOpen, open: true, success: submissionData.success, msg: submissionData.msg == "OK" ? "Message sent." : `Error message: "${submissionData.msg}" If you encounter further issues, please contact us directly at ootw.techmedia@gmail.com.` };
    console.log(toastState);
    setToastOpen(toastState);
  };

  PubSub.subscribe("FORM_SUBMISSION", displayToastSuccess);

  const handleToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    const toastState = { ...toastOpen, open: false };
    setToastOpen(toastState);
  };

  return (
    <ThemeProvider theme={Theme}>
      <Snackbar
        sx={{ mr: 4 }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={toastOpen.open}
        autoHideDuration={10000}
        onClose={handleToastClose}
      >
        <MuiAlert
          variant="filled"
          elevation={6}
          onClose={handleToastClose}
          severity={toastOpen.success ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {toastOpen.msg}
        </MuiAlert>
      </Snackbar>
    </ThemeProvider>
  );
}
